import React from "react";

const OurPartners: React.FC = () => {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <h1>Наши партнеры</h1>
        </div>
      </div>
      <div className="row text-center my-5">
        <div className="col my-auto">
          <img src="./images/our_partners_1.png" alt="our_partners" />
        </div>
        <div className="col my-auto">
          <img src="./images/our_partners_2.png" alt="our_partners" />
        </div>
        <div className="col my-auto">
          <img src="./images/our_partners_3.png" alt="our_partners" />
        </div>
        <div className="col my-auto">
          <img src="./images/our_partners_4.png" alt="our_partners" />
        </div>
        <div className="col my-auto">
          <img src="./images/our_partners_5.png" alt="our_partners" />
        </div>
        <div className="col my-auto">
          <img src="./images/our_partners_6.png" alt="our_partners" />
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
