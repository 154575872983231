import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "@brainhubeu/react-carousel/lib/style.css";

import Remont from "./pages/Remont";
import Error from "./pages/Error";

import "./app.scss";
import InDeveloping from "./pages/InDeveloping";
import Design from "./pages/Design";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/remont" />
        </Route>
        <Route exact path="/remont" component={Remont} />
        <Route exact path="/stroy" component={InDeveloping} />
        <Route exact path="/design" component={Design} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
};

export default App;
