import React from "react";
import RepairPackage from "./components/RepairPackage";
import classNames from "classnames";

import styles from "./repair.package.module.scss";
import { priceList2 } from "../../../../utils/data/priceListData";

const RepairPackages: React.FC = () => {
  const listPackages = priceList2.map((currentPackage, index) => {
    return <RepairPackage key={index} {...currentPackage} />;
  });

  return (
    <div className={classNames("container", styles.container)}>
      <div className={styles.row}>
        <div className="col-12">
          <br />
        </div>
        <div className={classNames("row row-cols-1 row-cols-sm-3 ")}>
          <div className={"col"}>
            <h1>
              Выберите свой пакет
              <br /> дизайна интерьера
            </h1>
          </div>
          {listPackages}
        </div>
      </div>
    </div>
  );
};

export default RepairPackages;
