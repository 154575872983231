import React from "react";

import NavBar from "layout/NavBar";
import { priceListFrom } from "../../../../utils/data/priceListData";

import style from "./main.module.scss";
import classNames from "classnames";
import HowWeAreWorkingIcon from "../../../../components/icons/HowWeAreWorkingIcon";
import LeaveARequestButton from "../../../../components/ui/LeaveARequestButton";
import { Avatar } from "@mui/material";

const Mobile = () => {
  const { price, priceType } = priceListFrom;
  return (
    <>
      <section className={style.main}>
        <NavBar />
        <div className="container mt-3">
          <div className={classNames("row", style.row)}>
            <div className="col d-block">
              <h1 className={style.repair}>РЕМОНТ КВАРТИР</h1>
              <h1 className={style.repair}>
                <h2>
                  от <span className={style.text_green}>{price}</span>{" "}
                  {priceType}
                </h2>
              </h1>
              <br />

              <p className={style.p}>
                <span className={style.text_green}>БЕСПЛАТНО</span> посчитаем
                стоимость ремонта с учетом вашего бюджета и личных пожеланий
              </p>
              <br />

              {/*<span className={style.discount}>*/}
              {/*  -50% скидки на дизайн*/}
              {/*  <br /> интерьера до конца зимы*/}
              {/*</span>*/}

              <LeaveARequestButton className={style.request_button} />
              <div className={style.how_working}>
                <HowWeAreWorkingIcon className={style.how_working_item} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="text-center"
        style={{ paddingTop: "-100px", position: "relative" }}
      >
        <br />
        <Avatar
          style={{
            border: "5px solid white",
          }}
          alt="A"
          src="./images/director.jpg"
          sx={{
            width: 200,
            height: 200,
            position: "absolute",
            top: -100,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <br />
        <br />
        <br />
        <br />
        <h1>Александр Кулинский</h1>
        <h6>Директор компании</h6>
        <p style={{ margin: 10 }}>
          Дипломированный специалист в области строительства и ремонта с
          20-летним стажем.
        </p>
      </div>
    </>
  );
};

export default Mobile;
