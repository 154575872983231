import React from "react";
import classNames from "classnames";

import styles from "./feed.back.module.scss";
import ViberIcon from "../../../../components/icons/ViberIcon";
import TelegramIcon from "../../../../components/icons/TelegramIcon";
import WhatsappIcon from "../../../../components/icons/WhatsappIcon";
import FeedbackForm from "../../../../components/forms/FeedbackForm";

const Feedback: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={classNames("container")}>
        <div className="row row-cols-1 row-cols-sm-3">
          <div className="col">
            <h3>Заполняйте форму обратной связи</h3>
            <p>
              Наш менеджер ответит на все вопросы и поможет выбрать удобное
              время, когда наш специалист сделает для вас индивидуальные замеры.
            </p>
            <ViberIcon />
            <TelegramIcon />
            <WhatsappIcon />
            <br />
            <span>Мы на связи в мессенджерах!</span>
            <br />
            <br />
          </div>
          <div className="col">
            <FeedbackForm />
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
