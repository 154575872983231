import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

type OwnProps = {
  className?: string;
};

const HowWeAreWorkingIcon: React.FC<OwnProps> = (props) => {
  const { className } = props;
  return null;

  return (
    <Link to="/">
      <img
        className={classNames("img", className)}
        src="images/play_how_work.svg"
        alt=""
      />
    </Link>
  );
};

export default HowWeAreWorkingIcon;
