import React from "react";

const FacebookIcon = () => {
  return (
    <a href="https://www.facebook.com/askstroy.od.ua/">
      <img className="m-2" src="images/facebook.svg" alt="1" />
    </a>
  );
};

export default FacebookIcon;
