import NavBar from "../../layout/NavBar";
import { Link } from "react-router-dom";
import React from "react";

const InDeveloping = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <NavBar />
          <h1>Страница в разработке</h1>
          <h2>
            <Link to="/remont">НА ГЛАВНУЮ</Link>
          </h2>
          <br />
          <img className="img-fluid" src="images/gif/develop.gif" />
        </div>
      </div>
    </div>
  );
};

export default InDeveloping;
