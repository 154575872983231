import React from "react";

const TelegramIcon = () => {
  return (
    <a href="https://t.me/Aleksandr_ASK">
      <img className="m-2" src="images/telegram.svg" alt="1" />
    </a>
  );
};

export default TelegramIcon;
