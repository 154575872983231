import React from "react";

import NavBar from "layout/NavBar";
import { priceListFrom } from "../../../../utils/data/priceListData";

import style from "./main.module.scss";
import classNames from "classnames";
import HowWeAreWorkingIcon from "../../../../components/icons/HowWeAreWorkingIcon";
import LeaveARequestButton from "../../../../components/ui/LeaveARequestButton";
import { Avatar } from "@mui/material";

const Main = () => {
  const { price, priceType } = priceListFrom;
  return (
    <>
      <section className={style.main}>
        <NavBar />
        <div className="container">
          <div className={classNames("row", style.row)}>
            <div className="col d-block">
              <h1 className={style.repair}>ДИЗАЙН ИНТЕРЬЕРА:</h1>
              <h3 className={style.complex}>
                от вас — идея, от нас — всё
                <br /> для её воплощения!{" "}
                <span className={style.price}>
                  от <span className={style.text_green}>12</span> у.е./м.кв
                </span>
              </h3>
              <p className={style.p}>
                Сделаем подробный план перепланировки, расстановки мебели,
                сантехники, электрики, потолков, раскладку плитки,
                3D-визуализацию
              </p>
              <LeaveARequestButton className={style.request_button} />
              <div className={style.how_working}>
                <HowWeAreWorkingIcon className={style.how_working_item} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row mt-5">
          <div className="col">
            <Avatar
              alt="A"
              src="./images/director.jpg"
              sx={{
                width: 300,
                height: 300,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <div className="col text-center">
            <br />
            <br />
            <h1>Александр Кулинский</h1>
            <br />
            <h6>Директор компании</h6>
            <br />
            <h5 style={{ margin: 10 }}>
              Дипломированный специалист в области строительства и ремонта с
              20-летним стажем.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
