import React from "react";

const styles = {
  textDecoration: "none",
};

export const vodafonePhoneNumber = (
  <a style={styles} href="tel:+380504468525">
    (050) 446-85-25
  </a>
);

export const kyivstarPhoneNumber = (
  <a style={styles} href="tel:+380964468525">
    (050) 446-85-25
  </a>
);
export const cityPhoneNumber = (
  <a style={styles} href="tel:+380487725059">
    (048) 772-50-59
  </a>
);
