import React from "react";

import style from "./repair.package.module.scss";

import classNames from "classnames";
import LeaveARequestButton from "components/ui/LeaveARequestButton";
import { PriceListType } from "utils/data/priceListData";
import HorizontalLine from "../../../../../../components/ui/HorizontalLine";

const RepairPackage: React.FC<PriceListType> = (props) => {
  const { name, price, list, priceType } = props;
  const listItems = list.map((listItem, index) => {
    return (
      <div key={index} className={style.check_text}>
        <i className={classNames("bi bi-check", style.check)} />
        &nbsp;<span key={index}>{listItem}</span>
        <br />
      </div>
    );
  });

  return (
    <div className={classNames("col")}>
      <div className={classNames("col", style.col)}>
        <div className="text-center">
          <br />
          <h5>{name}</h5>
          <br />
          <h6>
            <span className="text-black" style={{ fontWeight: 400 }}>
              от
            </span>{" "}
            {price}{" "}
            <span className="text-black" style={{ fontWeight: 400 }}>
              {priceType}
            </span>
          </h6>
          <br />
        </div>
        <HorizontalLine />
        <br />
        {listItems}
        <br />
        <br />
        <br />
        <div className="text-center">
          <LeaveARequestButton />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default RepairPackage;
