import React, { useState } from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";

import Button from "../../ui/Button";

interface Values {
  name: string;
  phone: string;
  email: string;
}

const initialValues: Values = {
  name: "",
  phone: "",
  email: "",
};

const FeedbackForm: React.FC = () => {
  const [isSubmitDone, setSubmitDone] = useState(false);

  const onSubmit = (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    // @ts-ignore
    console.log(window.dataLayer);
    // @ts-ignore
    window.dataLayer.push({'event': 'send_form'});
    // @ts-ignore
    console.log(window.dataLayer);
    fetch(window.location.origin + "/backend/mail.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values, null, 2),
    }).finally(() => {
      setSubmitDone(true);
      setSubmitting(false);
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Field
          id="name"
          name="name"
          placeholder="Имя*"
          required={true}
          className="form-control form-control-lg mb-3"
        />
        <Field
          id="phone"
          name="phone"
          placeholder="Телефон*"
          required={true}
          className="form-control form-control-lg mb-3"
        />
        <Field
          id="email"
          name="email"
          placeholder="example@mail.com"
          type="email"
          className="form-control form-control-lg mb-3"
        />
        <Button
          type="submit"
          className={isSubmitDone ? "w-100 btn-warning bg-warning" : "w-100"}
        >
          {isSubmitDone ? "ОТПРАВЛЕННО" : "Отправить"}
        </Button>
      </Form>
    </Formik>
  );
};
export default FeedbackForm;
