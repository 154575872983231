import React, { useEffect, useState } from "react";

import WorkCard from "./components/WorkCard";

import ImageList from "@mui/material/ImageList";

import styles from "./works.module.scss";
import useWindowDimensions from "../../../../utils/getWindowDimensions";

const Works: React.FC = () => {
  const [imagesUrls, setImagesUrls] = useState<String[]>([]);

  const [showUrlsCounter, setUrlsCounter] = useState(9);

  const ADD_COUNTER = 9;

  const addUrls = () => {
    const imagesUrlsLength = imagesUrls.length;
    if (imagesUrlsLength > showUrlsCounter + ADD_COUNTER) {
      setUrlsCounter(showUrlsCounter + ADD_COUNTER);
    } else {
      setUrlsCounter(imagesUrlsLength);
    }
  };

  useEffect(() => {
    fetch("https://askstroy.od.ua/backend/gallery.php")
      .then((res) => res.json())
      .then((res) => {
        setImagesUrls(res[2]);
      });
  }, []);

  const cards = imagesUrls
    .filter((element, index) => index < showUrlsCounter)
    .map((url, index) => {
      return <WorkCard key={index} url={url} index={index} />;
    });

  const windowDimensions = useWindowDimensions().width;

  const getWidth = () => {
    if (windowDimensions < 800) {
      return 1;
    }

    if (windowDimensions < 1280) {
      return 2;
    }
    return 3;
  };

  const widthNum = getWidth();

  return (
    <section className="container-fluid text-center">
      <h1 className={styles.projects}>
        Вот какие проекты мы <span>уже реализовали</span>
        <br /> для наших клиентов
      </h1>
      <ImageList variant="masonry" cols={widthNum} gap={8}>
        {cards}
      </ImageList>
      <button
        className="btn btn-success"
        onClick={addUrls}
        disabled={imagesUrls.length === showUrlsCounter}
      >
        Загрузить еще
      </button>
      <br />
      <br />
    </section>
  );
};

export default Works;
