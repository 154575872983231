import Feature from "./components/Feature";

import styles from "./our.features.module.scss";
import featuresData from "./data/featuresData";

const features = featuresData.map((feature, index) => {
  return <Feature key={index} {...feature} />;
});

const OurFeatures = () => {
  return (
    <section className={styles.background}>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 g-4">{features}</div>
      </div>
    </section>
  );
};

export default OurFeatures;
