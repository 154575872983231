import React from "react";

import Main from "./components/Main";
import Works from "./components/Works";
import RepairPackages from "./components/RepairPackages";
import Feedback from "./components/Feedback";
import AboutUs from "./components/AboutUs";
import OurTeam from "./components/OurTeam";
import HowWeAreWorking from "./components/HowWeAreWorking";
import OurPartners from "./components/OurPartners";
import Contacts from "./components/Contacts";
import Footer from "../../layout/Footer";
import PlansWorks from "./components/PlansWorks";
import MainMobile from "./components/Main/Mobile";
import useWindowDimensions from "../../utils/getWindowDimensions";

const Design: React.FC = () => {
  const { width } = useWindowDimensions();
  return (
    <section>
      {574 > width ? <MainMobile /> : <Main />}
      <Works />
      <RepairPackages />
      <PlansWorks />
      {/*<Feedback />*/}
      {/*<AboutUs />*/}
      {/*<OurTeam />*/}
      {/*<HowWeAreWorking />*/}
      <Feedback />
      <OurPartners />
      <Contacts />
      {/*<Footer />*/}
    </section>
  );
};

export default Design;
