export type PriceListType = {
  name: string;
  price: string;
  priceType: string;
  list: String[];
};

export const priceList: PriceListType[] = [
  {
    name: "Эконом",
    price: "180",
    priceType: "$/м2",
    list: [
      "Весь комплекс ремонто-отделочных работ",
      "Черновые материалы",
      "Натяжные потолки",
    ],
  },
  {
    name: "Стандарт",
    price: "220",
    priceType: "$/м2",
    list: [
      "Весь комплекс ремонто-отделочных работ",
      "Черновые материалы",
      "Натяжные потолки",
      "Гипсокартонные потолки (прямые)",
    ],
  },
  {
    name: "Все включено",
    price: "310",
    priceType: "$/м2",
    list: [
      "Весь комплекс ремонто-отделочных работ",
      "Черновые материалы",
      "Натяжные потолки",
      "Гипсокартонные потолки (прямые)",
      "Монтаж систем кондиционирования",
      " Чистовые материалы*",
    ],
  },
];

export const priceList2: PriceListType[] = [
  {
    name: "Эконом",
    price: "12",
    priceType: "y.e./м2",
    list: [
      "План обмерочный",
      "План перепланировки ",
      "План расстановки мебели и сантехники ",
      "План потолков ",
      "План электромонтажных работ  ",
      "План размещения теплых полов  ",
      "План напольных покрытий ",
    ],
  },
  {
    name: "Стандарт",
    price: "15",
    priceType: "y.e./м2",
    list: [
      "План обмерочный",
      "План перепланировки ",
      "План расстановки мебели и сантехники ",
      "План потолков ",
      "План электромонтажных работ  ",
      "План размещения теплых полов  ",
      "План напольных покрытий ",
      "План развертки стен с раскладкой плитки",
      "План развертки стен с указанием покрытий",
      "3D визуализации",
    ],
  },
];

export const priceListFrom = priceList[0];
