import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../layout/NavBar";

const Error: React.FC = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <NavBar />
          <h1>Страница не найдена</h1>
          <h2>
            <Link to="/remont">НА ГЛАВНУЮ</Link>
          </h2>
          <br />
          <img className="img-fluid" src="images/gif/error.gif" />
        </div>
      </div>
    </div>
  );
};

export default Error;
