import React from "react";

const WhatsappIcon = () => {
  return (
    <a href="https://wa.me/380504468525">
      <img className="m-2" src="images/whatsapp.svg" alt="1" />
    </a>
  );
};

export default WhatsappIcon;
