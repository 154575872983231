import React from "react";

const InstagramIcon = () => {
  return (
    <a href="https://www.instagram.com/aleksandr_kulinskiy/">
      <img className="m-2" src="images/instagram.svg" alt="1" />
    </a>
  );
};

export default InstagramIcon;
