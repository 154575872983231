import React from "react";
import FeedbackForm from "../../forms/FeedbackForm";
import Button from "../Button";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

type OwnProps = {
  className?: string;
};

const uuid = uuidv4().replace(/[^a-zA-Z]/g, "");

console.log(uuid);

const LeaveARequestButton: React.FC<OwnProps> = (props) => {
  const { className } = props;
  return (
    <>
      <Button
        type="button"
        className={classNames("btn btn-success", className)}
        data-bs-toggle="modal"
        data-bs-target={`#${uuid}`}
      >
        Оставить заявку
      </Button>

      <div
        className="modal fade"
        id={uuid}
        tabIndex={-1}
        aria-labelledby={uuid + "label"}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={uuid + "label"}>
                Заявка
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <FeedbackForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveARequestButton;
