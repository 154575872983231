import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const RepairOverview: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center mt-4 mb-1">
          <h1>Обзор ремонта</h1>
          <br />
          <div
            style={{
              margin: 0,
              position: "relative",
              padding: 0,
              paddingBottom: "calc(56.25%)",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/A_hjnr8deu8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RepairOverview;
