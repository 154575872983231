import React from "react";

import styles from "./feature.module.scss";
import { FeatureType } from "../../data/featuresData";
import classNames from "classnames";

const Feature: React.FC<FeatureType> = (props) => {
  const { imgSrc, name, text } = props;
  return (
    <div className="col">
      <div className={styles.col}>
        <div className="row">
          <div className={classNames("col-auto", styles.col_with_img)}>
            <img className="img" src={imgSrc} alt="feature" />
          </div>
          <div className="col">
            <h1 className={styles.name}>{name}</h1>
            <p className={styles.text}>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
