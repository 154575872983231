import React from "react";

export type PhotoFile = {
  fileName: string;
  description: string;
};

export type WorkCardType = {
  folderUrl: string;
  files: Array<PhotoFile>;
};

const cardsData: WorkCardType = {
  folderUrl: "images/photo_remont/",
  files: [
    {
      fileName: "image (38).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (46).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (44).jpg",
      description: "ТЕНИСТАЯ 15",
    },

    {
      fileName: "image (45).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (47).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (40).jpg",
      description: "ТЕНИСТАЯ 15",
    },

    {
      fileName: "image (43).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (48).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (41).jpg",
      description: "ТЕНИСТАЯ 15",
    },

    {
      fileName: "image (39).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (42).jpg",
      description: "ТЕНИСТАЯ 15",
    },
    {
      fileName: "image (1).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Внутренний интерьер',
    },
    {
      fileName: "image (2).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Lenny Kravitz room',
    },
    {
      fileName: "image (3).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Mick Jagger room',
    },
    {
      fileName: "image (4).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Bob Marley room',
    },
    {
      fileName: "image (5).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" John Lennon room',
    },
    {
      fileName: "image (6).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Jim Morrison room',
    },
    {
      fileName: "image (7).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL" Elvis Presley room',
    },
    {
      fileName: "image (8).jpg",
      description: "ROCK HOTEL",
    },
    {
      fileName: "image (9).jpg",
      description: "32 ЖЕМЧУЖИНА, ул. Каманина, 16А",
    },
    {
      fileName: "image (10).jpg",
      description: "32 ЖЕМЧУЖИНА, ул. Каманина, 16А",
    },
    {
      fileName: "image (11).jpg",
      description: "32 ЖЕМЧУЖИНА, ул. Каманина, 16А",
    },
    {
      fileName: "image (12).jpg",
      description: 'ЖК "ГАГАРИН ПЛАЗА 2",ул. Гагаринское плато, 5/2',
    },
    {
      fileName: "image (13).jpg",
      description: 'ЖК "ГАГАРИН ПЛАЗА 2",ул. Гагаринское плато, 5/2',
    },
    {
      fileName: "image (14).jpg",
      description: 'ЖК "ГАГАРИН ПЛАЗА 2",ул. Гагаринское плато, 5/2',
    },
    {
      fileName: "image (15).jpg",
      description: "УЛ. ГАГАРИНСКОЕ ПЛАТО, 9",
    },
    {
      fileName: "image (16).jpg",
      description: "УЛ. ГАГАРИНСКОЕ ПЛАТО, 9",
    },
    {
      fileName: "image (17).jpg",
      description: "УЛ. ГАГАРИНСКОЕ ПЛАТО, 9",
    },
    {
      fileName: "image (18).jpg",
      description: "УЛ. ГАГАРИНСКОЕ ПЛАТО, 9",
    },
    {
      fileName: "image (19).jpg",
      description: "ЖК РАДУЖНЫЙ, дом 10Б",
    },
    {
      fileName: "image (20).jpg",
      description: "34 ЖЕМЧУЖИНА",
    },
    {
      fileName: "image (21).jpg",
      description: "34 ЖЕМЧУЖИНА",
    },
    {
      fileName: "image (22).jpg",
      description: "34 ЖЕМЧУЖИНА",
    },
    {
      fileName: "image (23).jpg",
      description: "34 ЖЕМЧУЖИНА",
    },
    {
      fileName: "image (24).jpg",
      description: "ЖК ЛИМНОС",
    },
    {
      fileName: "image (25).jpg",
      description: "32 ЖЕМЧУЖИНА, ул. Каманина, 16А",
    },
    {
      fileName: "image (26).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (27).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (28).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (29).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (30).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (31).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (32).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
    {
      fileName: "image (33).jpg",
      description: 'ул.АРМЕЙСКАЯ 8г "ROCK HOTEL"',
    },
  ],
};

export default cardsData;
