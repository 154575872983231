import React from "react";

import Main from "./components/Main";
import MainMobile from "./components/Main/Mobile";
import OurFeatures from "./components/OurFeatures";
import Works from "./components/Works";
import RepairPackages from "./components/RepairPackages";
import Feedback from "./components/Feedback";
import AboutUs from "./components/AboutUs";
import HowWeAreWorking from "./components/HowWeAreWorking";
import OurPartners from "./components/OurPartners";
import Contacts from "./components/Contacts";
import useWindowDimensions from "../../utils/getWindowDimensions";
import RepairOverview from "./components/RepairOverview";

const Remont: React.FC = () => {
  const { width } = useWindowDimensions();

  return (
    <section>
      {574 > width ? <MainMobile /> : <Main />}
      <OurFeatures />
      <RepairOverview />
      <Works />
      <RepairPackages />
      <Feedback />
      <AboutUs />
      {/*<OurTeam />*/}
      <HowWeAreWorking />
      <Feedback />
      <OurPartners />
      <Contacts />
      {/*<Footer />*/}
    </section>
  );
};

export default Remont;
