import styles from "./features.data.module.scss";

export type FeatureType = {
  imgSrc: string;
  name: JSX.Element;
  text: string;
};

const featuresData: Array<FeatureType> = [
  {
    imgSrc: "./images/bedroom.svg",
    name: (
      <>
        Больше <br />
        <span className={styles.span}>10 лет</span>
      </>
    ),
    text: "Делаем ремонты “под ключ”. Это значит, что вы только озвучиваете нам свои пожелания. А мы с нуля  создаем проект, помогаем закупить материалы, выполняем все черновые и чистовые работы.",
  },
  {
    imgSrc: "./images/bedroom.svg",
    name: (
      <>
        Вы оплачиваете работы <br />
        только <span className={styles.span}>по факту</span> их выполнения
      </>
    ),
    text: "Никаких предоплат. Мы делаем этап работ, и только убедившись в качестве выполненной работы вы оплачиваете по акту.",
  },
  {
    imgSrc: "./images/bedroom.svg",
    name: (
      <>
        Не переплачиваете
        <br />
        за <span className={styles.span}>материалы</span>
      </>
    ),
    text: "Поможем выбрать и закупить все материалы по самой выгодной стоимости. Вы получите скидку до 10% на покупку отделочных материалов, мебели, дверей, сантехники и даже установку кондиционера и сигнализации.",
  },
  {
    imgSrc: "./images/bedroom.svg",
    name: (
      <>
        3 года
        <br />
        <span className={styles.span}>гарантии</span>
      </>
    ),
    text: "на выполненные работы получают наши клиенты. Каждый этап наши специалисты фотографируют и снимают на видео, чтобы предоставлять вам подробный отчет.\n",
  },
];

export default featuresData;
