import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";

import WorkCard from "./components/WorkCard";

import useWindowDimensions from "utils/getWindowDimensions";

import styles from "./works.module.scss";
import cardsData from "./data/cardsData";
import { createTheme, ThemeProvider } from "@mui/material";

const ADD_COUNTER = 9;

const materialTheme = createTheme({
  // @ts-ignore
  overrides: {
    MuiImageListItemBar: {
      root: {
        backgroundColor: "red",
      },
    },
  },
});

// MuiImageListItemBar-root

const Works: React.FC = () => {
  const [showUrlsCounter, setUrlsCounter] = useState(9);

  const { folderUrl, files } = cardsData;

  const addUrls = () => {
    const imagesUrlsLength = files.length;
    if (imagesUrlsLength > showUrlsCounter + ADD_COUNTER) {
      setUrlsCounter(showUrlsCounter + ADD_COUNTER);
    } else {
      setUrlsCounter(imagesUrlsLength);
    }
  };

  const cards = files
    .filter((_, index) => index < showUrlsCounter)
    .map((file, index) => {
      const { fileName, description } = file;
      return (
        <ThemeProvider theme={materialTheme}>
          <WorkCard
            key={index}
            fileName={fileName}
            description={description}
            folderUrl={folderUrl}
          />
        </ThemeProvider>
      );
    });

  const windowDimensions = useWindowDimensions().width;

  const getColsForPhotos = () => {
    if (windowDimensions < 800) {
      return 1;
    }
    if (windowDimensions < 1280) {
      return 2;
    }
    return 3;
  };

  const colsForPhotos = getColsForPhotos();

  return (
    <section className="container-fluid text-center">
      <h1 className={styles.projects}>
        Вот какие проекты мы <span>уже реализовали</span>
        <br /> для наших клиентов
      </h1>

      <ImageList cols={colsForPhotos} gap={8}>
        {cards}
      </ImageList>

      <button
        className="btn btn-success"
        onClick={addUrls}
        disabled={files.length === showUrlsCounter}
      >
        Загрузить еще
      </button>
      <br />
      <br />
    </section>
  );
};

export default Works;
