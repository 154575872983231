import React from "react";
import RepairPackage from "./components/RepairPackage";
import classNames from "classnames";

import styles from "./repair.package.module.scss";
import { priceList } from "../../../../utils/data/priceListData";

const RepairPackages: React.FC = () => {
  const listPackages = priceList.map((currentPackage, index) => {
    return <RepairPackage key={index} {...currentPackage} />;
  });

  return (
    <div className={classNames("container", styles.container)}>
      <div className={styles.row}>
        <div className="col-12">
          <h1>
            Выберите свой пакет
            <br /> ремонтных работ
          </h1>
          <br />
        </div>
        <div className={classNames("row row-cols-1 row-cols-sm-3 ")}>
          {listPackages}
        </div>
        <div className={classNames("col-12", styles.materials)}>
          <span>Чистовые материалы: *</span>
          <br />
          <br />
          <p>
            Межкомнатные двери; плитка; обои; ламинат; плинтуса; сантехнические
            приборы: унитаз, ванна, умывальник с тумбой, зеркало, смесители;
            люстры; вентиляторы; точечные светильники; розетки, выключатели
            фирмы Shnaider; подоконники Danke
          </p>
        </div>
      </div>
    </div>
  );
};

export default RepairPackages;
