import React from "react";
import { Link } from "react-router-dom";
import { LinkType } from "../../data/links";

const NavBarLi: React.FC<LinkType> = (props) => {
  const { name, href } = props;
  return (
    <li className="nav-item">
      <Link className="nav-link" to={href}>
        {name}
      </Link>
    </li>
  );
};

export default NavBarLi;
