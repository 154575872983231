import React from "react";
import { ListOfWorksType } from "../../data/listOfWorks";

import styles from "./item.card.module.scss";
import classNames from "classnames";
import LeaveARequestButton from "../../../../../../components/ui/LeaveARequestButton";

type OwnProps = {
  counter: number;
};

type AllProps = OwnProps & ListOfWorksType;

const ItemCard: React.FC<AllProps> = (props) => {
  const { name, text, isSendButton, counter } = props;

  const SendButton = () => {
    if (isSendButton) {
      return <LeaveARequestButton />;
    }
    return null;
  };

  return (
    <div className="col">
      <div className={classNames("col", styles.col)}>
        <h3>{counter + ". " + name}</h3>
        <p>{text}</p>
        <SendButton />
      </div>
    </div>
  );
};

export default ItemCard;
