import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

type OwnProps = {
  className?: string;
};

const Button: React.FC<
  OwnProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const { className, children } = props;
  return (
    <button {...props} className={classNames(styles.button, className)}>
      {children}
    </button>
  );
};

export default Button;
