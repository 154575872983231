import React from "react";

import listOfWorks from "./data/listOfWorks";
import ItemCard from "./components/ItemCard";
import HowWeAreWorkingIcon from "components/icons/HowWeAreWorkingIcon";

import styles from "./styles.module.scss";
import classNames from "classnames";

const itemCardList = listOfWorks.map((card, index) => {
  const counter = index + 1;
  return <ItemCard key={index} counter={counter} {...card} />;
});

const HowWeAreWorking: React.FC = () => {
  return (
    <section className={classNames("pt-5 pb-5", styles.container)}>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-3 g-4">
          <div className={classNames("col", styles.col)}>
            <h1>
              Как мы
              <br /> работаем <i className="bi bi-arrow-right" />
            </h1>
            <HowWeAreWorkingIcon />
          </div>
          {itemCardList}
        </div>
      </div>
    </section>
  );
};

export default HowWeAreWorking;
