import React from "react";
import ViberIcon from "../../../../components/icons/ViberIcon";
import TelegramIcon from "../../../../components/icons/TelegramIcon";
import WhatsappIcon from "../../../../components/icons/WhatsappIcon";
import InstagramIcon from "../../../../components/icons/InstagramIcon";
import FacebookIcon from "../../../../components/icons/FacebookIcon";
import PhoneIcon from "../../../../components/icons/PhoneIcon";
import {
  kyivstarPhoneNumber,
  cityPhoneNumber,
  vodafonePhoneNumber,
} from "../../../../utils/data/phoneNumbersData";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Contacts: React.FC = () => {
  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-sm-2">
        <div className={classNames("col", styles.col)}>
          <h1>Контакты</h1>
          <h6>
            Давайте дружить
            <br /> в соц сетях
          </h6>
          <InstagramIcon />
          <FacebookIcon />
          <h2>г. Одесса ул. Космонавтов 32</h2>
          <h5>
            <PhoneIcon /> {vodafonePhoneNumber}
          </h5>
          <ViberIcon />
          <TelegramIcon />
          <WhatsappIcon />
          <br />
          <span>Мы на связи в мессенджерах!</span>
          <br />
          <br />
        </div>
        <div className="col">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2749.8661165991334!2d30.713109615836498!3d46.4315331763959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c633a651e3fd05%3A0x49892d225118f17!2z0J7QntCeINCQ0KHQmiDQodCi0KDQntCZ!5e0!3m2!1sru!2sua!4v1633164142263!5m2!1sru!2sua"
            width="100%"
            height="541"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
