import React from "react";

import styles from "./about.us.module.scss";
import classNames from "classnames";

const AboutUs: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={classNames("container")}>
        <div className="row row-cols-1 row-cols-sm-2">
          <div className="col">
            <img
              className="img-fluid"
              src="images/about_us_image_1.png"
              alt="about_us_image_1"
            />
          </div>
          <div className="col">
            <h1>И еще несколько слов о нас</h1>
            <p>
              Мы — команда профессиональных строителей. Сотрудники компании
              работают в этой сфере уже более 10 лет. Поэтому мы точно знаем,
              как сделать ремонт, качество которого не подведет вас в течение
              многих лет. С нами вы точно будете знать сколько и за что платите.
              Вместе составим подробную смету с учетом стоимости нашей работы,
              закупленных материалов, мебели и т.д.
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-sm-2">
          <div className="col">
            <p>
              Процесс построен так, чтобы при необходимости вы получили ремонт
              квартиры “под ключ”. У нас есть все необходимые подрядчики для
              завершения полного цикла ремонта. Вы сэкономите своё время и
              нервы. Нужно будет только посмотреть каталоги и выбрать материалы,
              по цене, качеству и дизайну соответствующие вашим запросам.
            </p>
            <p>
              Все подрядчики — наши давние надежные партнеры. Организуем не
              только закупку, но и установку дверей, окон, натяжных потолков,
              кондиционеров, системы сигнализации. Все отделочные материалы,
              плитку, двери, сантехнику и т.п. вы получите вовремя и с хорошей
              скидкой!
            </p>
          </div>
          <div className="col">
            <img
              className="img-fluid"
              src="images/about_us_image_2.png"
              alt="about_us_image_2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
