import React from "react";
import { ImageListItem, ImageListItemBar } from "@mui/material";
import { PhotoFile, WorkCardType } from "../../data/cardsData";

type OwnProps = Omit<WorkCardType, "files">;

const WorkCard: React.FC<OwnProps & PhotoFile> = (props) => {
  const { folderUrl, fileName, description } = props;

  const url = folderUrl + fileName;

  return (
    <ImageListItem>
      <img
        src={`${url}?w=164&h=164&fit=crop&auto=format`}
        srcSet={`${url}?w=164&h=164&fit=crop&auto=format`}
        alt={`${url}`}
        loading="lazy"
      />
      <ImageListItemBar title={description} subtitle={""} />
    </ImageListItem>
  );
};

export default WorkCard;
