import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { ImageListItem, ImageListItemBar } from "@mui/material";
import Box from "@mui/material/Box";
import useWindowDimensions from "utils/getWindowDimensions";
import { Button, Modal } from "react-bootstrap";

const uuid = uuidv4().replace(/[^a-zA-Z]/g, "");

console.log(uuid);

const WorkCard: React.FC<any> = (props) => {
  const windowSize = useWindowDimensions();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "3px solid white",
    boxShadow: 24,
    width: windowSize.width,
    height: windowSize.height,
  };

  const { url } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ImageListItem key={url}>
        <img
          src={`${url}?w=248&fit=crop&auto=format`}
          srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={`${url}`}
          loading="lazy"
          onClick={handleShow}
        />
      </ImageListItem>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img
            src={`${url}?w=248&fit=crop&auto=format`}
            srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={`${url}`}
            loading="lazy"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkCard;

// old
//
// <div className={styles.card}>
//     <img className={styles.cardImg} src={url} />
// <div className={styles.card_content}>
//   <div className={classNames(styles.in_more_detail)}>
//     <i
//         className="bi bi-search"
//         style={{
//           textShadow: "black 0 0 10px",
//           fontSize: 55,
//         }}
//     />
//     <br />
//     <Button variant="success" onClick={handleShow}>
//       Подробнее
//     </Button>
//
//     <Modal size="xl" show={show} onHide={handleClose}>
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Carousel plugins={carouselSettings}>{images}</Carousel>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   </div>
//   <div className={styles.info}>
//     {/*<strong>{name}</strong>*/}
//     <br />
//     <p>{text}</p>
//   </div>
// </div>
// </div>
