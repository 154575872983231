export type ListOfWorksType = {
  name: string;
  text: string;
  isSendButton?: boolean;
};

const listOfWorks: ListOfWorksType[] = [
  {
    name: "Подайте заявку",
    text: "Подаете заявку. Отвечаем на все ваши вопросы. Назначаем удобное время, когда специалист приезжает к вам для замеров и консультации.",
    isSendButton: true,
  },
  {
    name: "Составляем подробную смету",
    text: "с разбитием работ на этапы. Делаем подробный дизайн-проект с визуализацией будущего ремонта со всеми деталями интерьера.",
  },
  {
    name: "Заключаем договор.",
    text: "Четко обозначаем сроки окончания каждого этапа работ. Закупаем для вас материалы по выгодной цене.",
  },
  {
    name: "Приступаем к ремонту.",
    text: "Регулярно предоставляем отчет о проделанной работе. Вы видите не просто “красивую картинку” — конечный результат. А будете уверены, что всё — от отделки до чистовых работ — сделано качественно.",
  },
  {
    name: "Сдаем объект.",
    text: "Вы утверждаете нашу работу и получаете 3 года гарантии в соответствии с договором.",
  },
];

export default listOfWorks;
