import React from "react";

export type PhotoFile = {
  fileName: string;
  description: string;
};

export type WorkCardType = {
  folderUrl: string;
  files: Array<PhotoFile>;
};

const cardsData: WorkCardType = {
  folderUrl: "images/photo_design/",
  files: [
    {
      fileName: "image (1).jpg",
      description: "Добровольского, 138",
    },
    {
      fileName: "image (2).jpg",
      description: "Добровольского, 138",
    },
    {
      fileName: "image (3).jpg",
      description: "Добровольского, 138",
    },
    {
      fileName: "image (4).jpg",
      description: "Добровольского, 138",
    },
    {
      fileName: "image (5).jpg",
      description: "46 Жемчужина",
    },
    {
      fileName: "image (6).jpg",
      description: "46 Жемчужина",
    },
    {
      fileName: "image (7).jpg",
      description: "46 Жемчужина",
    },
    {
      fileName: "image (47).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (48).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (49).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (44).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (45).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (46).jpg",
      description: "ЖК: Четыре Сезона",
    },
    {
      fileName: "image (8).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (9).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (10).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (11).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (12).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (13).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (14).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (15).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (16).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (17).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (18).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (19).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (20).jpg",
      description: "пер. Нечипуренко, 4",
    },
    {
      fileName: "image (21).jpg",
      description: "Гагаринсоке плато, 9",
    },
    {
      fileName: "image (22).jpg",
      description: "Гагаринсоке плато, 9",
    },
    {
      fileName: "image (23).jpg",
      description: "Гагаринсоке плато, 9",
    },
    {
      fileName: "image (24).jpg",
      description: "Гагаринсоке плато, 9",
    },
    {
      fileName: "image (25).jpg",
      description: "Гагаринсоке плато, 9",
    },
    {
      fileName: "image (26).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (27).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (28).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (29).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (30).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (31).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (32).jpg",
      description: "44 Жемчужина",
    },
    {
      fileName: "image (33).jpg",
      description: "Заболотного, 58",
    },
    {
      fileName: "image (34).jpg",
      description: "Заболотного, 58",
    },
    {
      fileName: "image (35).jpg",
      description: "Заболотного, 58",
    },
    {
      fileName: "image (36).jpg",
      description: "Заболотного, 58",
    },
    {
      fileName: "image (37).jpg",
      description: "Заболотного, 58",
    },
    {
      fileName: "image (38).jpg",
      description: "ул. Космонавтов, 11а",
    },
    {
      fileName: "image (39).jpg",
      description: "ул. Космонавтов, 11а",
    },
    {
      fileName: "image (40).jpg",
      description: "Куйбышева, 28",
    },
    {
      fileName: "image (41).jpg",
      description: "Куйбышева, 28",
    },
    {
      fileName: "image (42).jpg",
      description: "Куйбышева, 28",
    },
    {
      fileName: "image (43).jpg",
      description: "Куйбышева, 28",
    },
  ],
};

export default cardsData;
