import React from "react";

const ViberIcon = () => {
  return (
    <a href="viber://chat?number=%2B380504468525">
      <img className="m-2" src="images/viber.svg" alt="1" />
    </a>
  );
};

export default ViberIcon;
