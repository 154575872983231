import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import NavBarLi from "./components/NavBarLi";

import styles from "./navbar.module.scss";
import links from "./data/links";
import { workTime } from "../../utils/data/workTimeData";
import { vodafonePhoneNumber } from "../../utils/data/phoneNumbersData";
import PhoneIcon from "../../components/icons/PhoneIcon";
import ViberIcon from "../../components/icons/ViberIcon";
import TelegramIcon from "../../components/icons/TelegramIcon";
import WhatsappIcon from "../../components/icons/WhatsappIcon";

const linksElements = links.map((link, index) => {
  return <NavBarLi key={index} {...link} />;
});

const NavBar: React.FC = () => {
  return (
    <nav className={classNames("navbar navbar-expand-lg navbar-light")}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="./images/logo.svg" alt="" height="20" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{
            border: "1px solid #34b248",
          }}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto">{linksElements}</ul>
          <div className="m-auto">
            <ViberIcon />
            <TelegramIcon />
            <WhatsappIcon />
          </div>
          <div>
            <span> </span>
            <span className={classNames(styles.phone)}>
              <PhoneIcon /> {vodafonePhoneNumber}
            </span>
            <br />
            <div className={classNames(styles.work, "col")}>{workTime}</div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
