export type LinkType = {
  name: string;
  href: string;
};

const links: Array<LinkType> = [
  { name: "Дизайн интерьера", href: "/design" },
  { name: "Ремонт квартир", href: "/remont" },
  { name: "Строительство домов", href: "/stroy" },
];

export default links;
